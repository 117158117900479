const ctaButtonFields = [
  {
    id: 'ctaButtonCount',
    label: 'call to action buttons',
    type: 'select',
    options: [
      { label: 'None', value: 'none' },
      { label: '1 button', value: '1' },
      { label: '2 buttons', value: '2' },
    ],
  },
  {
    id: 'ctaText',
    label: 'primary cta text',
    type: 'text',
    conditions: [{ key: 'ctaButtonCount', values: ['1', '2'] }],
  },
  {
    id: 'ctaUrl',
    label: 'primary cta url',
    type: 'text',
    conditions: [{ key: 'ctaButtonCount', values: ['1', '2'] }],
  },
  {
    id: 'ctaEvent',
    label: 'primary cta event',
    type: 'text',
    conditions: [{ key: 'ctaButtonCount', values: ['1', '2'] }],
  },
  {
    id: 'cta2Text',
    label: 'secondary cta text',
    type: 'text',
    conditions: [{ key: 'ctaButtonCount', values: ['2'] }],
  },
  {
    id: 'cta2Url',
    label: 'secondary cta url',
    type: 'text',
    conditions: [{ key: 'ctaButtonCount', values: ['2'] }],
  },
  {
    id: 'cta2Event',
    label: 'secondary cta event',
    type: 'text',
    conditions: [{ key: 'ctaButtonCount', values: ['2'] }],
  },
];
const mediaFields = [
  {
    id: 'mediaType',
    label: 'media type',
    type: 'select',
    options: [
      { label: 'Image', value: 'image' },
      { label: 'Video', value: 'video' },
    ],
  },
  {
    id: 'image',
    label: 'image',
    type: 'image',
    conditions: [{ key: 'mediaType', values: ['image'] }],
  },
  {
    id: 'videoUrl',
    label: 'video url',
    type: 'text',
    conditions: [{ key: 'mediaType', values: ['video'] }],
  },
];

export const customFields = {
  text: [
    {
      id: 'html',
      label: 'html content (remember to include <p> tags)',
      type: 'textarea',
    },
    ...ctaButtonFields,
  ],
  form: [
    { id: 'content', label: 'content', type: 'text' },
    { id: 'formDocId', label: 'formDocId', type: 'text' },
    { id: 'image', label: 'image', type: 'image' },
  ],
  hero: [
    { id: 'description', label: 'description', type: 'text' },
    {
      id: 'backgroundType',
      label: 'background type',
      type: 'select',
      options: [
        { label: 'Splash image (full)', value: 'splash' },
        { label: 'Side image (right)', value: 'sideImageRight' },
      ],
    },
    {
      id: 'imageId',
      label: 'image id',
      type: 'text',
      conditions: [
        { key: 'backgroundType', values: ['splash', null, undefined] },
      ],
    },
    {
      id: 'image',
      label: 'image',
      type: 'image',
      conditions: [{ key: 'backgroundType', values: ['sideImageRight'] }],
    },
    ...ctaButtonFields,
    {
      id: 'bottomScrollEnabled',
      label: 'enable bottom scroll?',
      type: 'select',
      options: [
        { label: 'Disable bottom scroll', value: 'disabled' },
        { label: 'Enable bottom scroll', value: 'enabled' },
      ],
    },
    {
      id: 'bottomScrollItems',
      label: 'bottom scroll items',
      type: 'repeater',
      conditions: [{ key: 'bottomScroll', values: ['enabled'] }],
      fields: [
        { id: 'title', label: 'title', type: 'text' },
        { id: 'description', label: 'description', type: 'text' },
        { id: 'url', label: 'url', type: 'text' },
        ...mediaFields,
      ],
    },
  ],
  mediaText: [
    {
      id: 'html',
      label: 'html content (remember to include <p> tags)',
      type: 'textarea',
    },
    ...mediaFields,
    ...ctaButtonFields,
  ],
  chapterHeading: [
    {
      id: 'html',
      label: 'html content (remember to include <p> tags)',
      type: 'textarea',
    },
    ...mediaFields,
    ...ctaButtonFields,
  ],
  tableOfContents: [
    {
      id: 'html',
      label: 'optional html content (remember to include <p> tags)',
      type: 'textarea',
    },
    { id: 'image', label: 'image', type: 'image' },
  ],
  contactInfo: [
    {
      id: 'html',
      label: 'html content (remember to include <p> tags)',
      type: 'textarea',
    },
    {
      id: 'email',
      label: 'email address (optional)',
      type: 'text',
    },
    {
      id: 'phone',
      label: 'phone number (optional)',
      type: 'text',
    },
    {
      id: 'address',
      label: 'address (optional)',
      type: 'textarea',
    },
    {
      id: 'showImage',
      label: 'show image?',
      type: 'select',
      options: [
        { label: 'No', value: 'no' },
        { label: 'Yes', value: 'yes' },
      ],
    },
    {
      id: 'image',
      label: 'image',
      type: 'image',
      conditions: [{ key: 'showImage', values: ['yes'] }],
    },
    ...ctaButtonFields,
  ],
  testimonial: [
    {
      id: 'quote',
      label: 'testimonial quote',
      type: 'textarea',
    },
    {
      id: 'clientName',
      label: 'client name',
      type: 'text',
    },
    {
      id: 'clientTitle',
      label: 'client title',
      type: 'text',
    },
    {
      id: 'image',
      label: 'client photo',
      type: 'image',
    },
    ...ctaButtonFields,
  ],
};

export const imageFields = [
  { id: 'image-id', label: 'image id', type: 'text' },
  { id: 'image-alt', label: 'alt text', type: 'text' },
  { id: 'image-width', label: 'image width', type: 'number' },
  { id: 'image-height', label: 'image height', type: 'number' },
];
