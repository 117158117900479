import { useState, useEffect } from 'react';
import { firestore } from '../../utils/firebase';
import {
  query,
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  addDoc,
} from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/page';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import PendingIcon from '@mui/icons-material/Pending';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import asyncForEach from '../../utils/asyncForEach';
import { get, sortBy } from 'lodash';
import BlockModal from './blockModal';

const modalStyle = {
  position: 'fixed',
  top: '10%',
  right: '10%',
  bottom: '10%',
  left: '10%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

const homepageTemplate = {
  url: '/',
  title: 'Homepage',
  published: true,
  seo: {
    title: 'Homepage',
    description: '',
    imageId: '',
  },
};
const errorTemplate = {
  url: '/404',
  title: '404 page',
  published: true,
  seo: {
    title: 'Page Not Found',
    imageId: '96f200c6-a475-45d4-c7a5-ed5523729800',
  },
};

function BlocksPage() {
  const { websiteKey, pageId } = useParams();
  const [blocks, setBlocks] = useState([]);
  const [meta, setMeta] = useState({});
  const [editingMeta, setEditingMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [softDeleting, setSoftDeleting] = useState(false);
  const [deleteValue, setDeleteValue] = useState('');
  const [newBlockType, setNewBlockType] = useState(null);
  const [newBlockTitle, setNewBlockTitle] = useState('');
  const [newBlockSort, setNewBlockSort] = useState(10);
  const [currentBlock, setCurrentBlock] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // fetch page data from firestore
    const fetchMeta = async () => {
      const pageRef = doc(firestore, 'websites', websiteKey, 'pages', pageId);
      const pageDoc = await getDoc(pageRef);
      const pageData = pageDoc.data();
      if (pageData) {
        setMeta(pageData);
      } else {
        // if homepage/404, then generate the page
        if (pageId === 'homepage' || pageId === '404') {
          const template =
            pageId === 'homepage' ? homepageTemplate : errorTemplate;
          await setDoc(pageRef, template);
          const newDoc = await getDoc(pageRef);
          const newData = newDoc.data();
          setMeta(newData);
        }
      }
    };
    fetchMeta();

    // fetch content blocks from firestore
    const fetchBlocks = async () => {
      const blocksCollection = collection(
        firestore,
        'websites',
        websiteKey,
        'pages',
        pageId,
        'blocks'
      );
      let q = query(blocksCollection);
      const fetchedDocs = await getDocs(q);
      const theDocs = [];
      fetchedDocs.forEach((doc) => {
        const docData = {
          firebaseId: doc.id,
          ...doc.data(),
        };
        theDocs.push(docData);
      });
      setBlocks(sortBy(theDocs, (o) => o.sort));
    };
    fetchBlocks();
  }, [websiteKey, pageId]);

  const fetchPageMeta = async () => {
    const pageRef = doc(firestore, 'websites', websiteKey, 'pages', pageId);
    const pageDoc = await getDoc(pageRef);
    const pageData = pageDoc.data();
    if (pageData) {
      setMeta(pageData);
    } else {
      // if homepage/404, then generate the page
      if (pageId === 'homepage' || pageId === '404') {
        const template =
          pageId === 'homepage' ? homepageTemplate : errorTemplate;
        await setDoc(pageRef, template);
        const newDoc = await getDoc(pageRef);
        const newData = newDoc.data();
        setMeta(newData);
      }
    }
  };
  const fetchBlockData = async () => {
    const blocksCollection = collection(
      firestore,
      'websites',
      websiteKey,
      'pages',
      pageId,
      'blocks'
    );
    let q = query(blocksCollection);
    const fetchedDocs = await getDocs(q);
    const theDocs = [];
    fetchedDocs.forEach((doc) => {
      const docData = {
        firebaseId: doc.id,
        ...doc.data(),
      };
      theDocs.push(docData);
    });
    setBlocks(sortBy(theDocs, (o) => o.sort));
  };

  const handleAddBlock = () => {
    setNewBlockType('text');
    setNewBlockTitle('New');
    setNewBlockSort(10);
  };
  const handleStopAddingBlock = () => {
    setNewBlockType(null);
  };
  const handleEditBlockType = (e) => {
    setNewBlockType(e.target.value);
  };
  const handleEditBlockTitle = (e) => {
    setNewBlockTitle(e.target.value);
  };
  const handleEditBlockSort = (e) => {
    setNewBlockSort(e.target.value);
  };
  const handleSaveNewBlock = async (e) => {
    e.preventDefault();
    setLoading(true);

    // save new block to firestore
    const blocksCollection = collection(
      firestore,
      'websites',
      websiteKey,
      'pages',
      pageId,
      'blocks'
    );
    const newDocRef = await addDoc(blocksCollection, {
      type: newBlockType,
      title: newBlockTitle,
      sort: parseInt(newBlockSort, 10),
    });
    await setDoc(newDocRef, { id: newDocRef.id }, { merge: true });

    // refresh screen data
    fetchBlockData();
    setTimeout(() => {
      setLoading(false);
      handleStopAddingBlock();
    }, 500);
  };

  const handleBlockClick = (blockData) => {
    setCurrentBlock(blockData);
  };
  const handleStopEditingBlock = () => {
    setCurrentBlock(null);
  };

  const handleOpenMeta = (metaData) => {
    setEditingMeta(metaData);
  };
  const handleCloseMeta = () => {
    setEditingMeta(null);
  };
  const handleEditMeta = (e) => {
    const metaType = get(editingMeta, ['type'], null);
    const newValue =
      metaType === 'boolean' ? Boolean(e.target.checked) : e.target.value;
    setEditingMeta({
      ...editingMeta,
      value: newValue,
    });
  };
  const handleSaveMeta = async (e) => {
    e.preventDefault();
    setLoading(true);
    const metaKey = get(editingMeta, ['key'], null);
    const metaType = get(editingMeta, ['type'], null);
    const metaValue = get(editingMeta, ['value'], null);
    const newValue =
      metaType === 'text'
        ? metaValue
        : metaType === 'boolean'
        ? Boolean(metaValue)
        : parseInt(metaValue, 10);
    if (metaKey && (metaValue || typeof newValue === 'boolean')) {
      const pageDoc = doc(firestore, 'websites', websiteKey, 'pages', pageId);
      if (metaKey.startsWith('seo.')) {
        const newMetaKey = metaKey.replace('seo.', '');
        await setDoc(
          pageDoc,
          { seo: { [newMetaKey]: newValue } },
          { merge: true }
        );
      } else {
        await setDoc(pageDoc, { [metaKey]: newValue }, { merge: true });
      }
    }

    // refresh data
    fetchPageMeta();
    setTimeout(() => {
      setLoading(false);
      handleCloseMeta();
    }, 500);
  };

  const handleSoftDeletePage = async () => {
    setSoftDeleting(true);
    setDeleteValue('');
  };
  const handleStopSoftDeleting = async () => {
    setSoftDeleting(false);
  };
  const handleEditDelete = (e) => {
    setDeleteValue(e.target.value);
  };
  const handleDeletePage = async (e) => {
    e.preventDefault();
    setLoading(true);

    // delete page's content blocks from firestore
    const blocksCollection = collection(
      firestore,
      'websites',
      websiteKey,
      'pages',
      pageId,
      'blocks'
    );
    let q = query(blocksCollection);
    const fetchedDocs = await getDocs(q);
    const blockDeletionProcessor = async (item) => {
      const docRef = doc(
        firestore,
        'websites',
        websiteKey,
        'pages',
        pageId,
        'blocks',
        item.id
      );
      await deleteDoc(docRef);
    };
    await asyncForEach(fetchedDocs, blockDeletionProcessor);

    // delete page itself from firestore
    const pageRef = doc(firestore, 'websites', websiteKey, 'pages', pageId);
    await deleteDoc(pageRef);

    // redirect to all pages
    setTimeout(() => {
      setLoading(false);
      navigate('/' + websiteKey + '/pages');
    }, 500);
  };

  if (websiteKey === 'all') return null;

  const url = get(meta, ['url'], '');
  const title = get(meta, ['title'], '');
  const sort = get(meta, ['sort'], 0);
  const published = get(meta, ['published'], false);
  const seo = get(meta, ['seo'], {});
  const seoTitle = get(seo, ['title'], '');
  const seoImageId = get(seo, ['imageId'], '');
  const seoDescription = get(seo, ['description'], '');

  const metaModalLabel = get(editingMeta, ['label'], '');
  const metaModalValue = get(editingMeta, ['value'], '');
  const metaModalType = get(editingMeta, ['type'], '');

  return (
    <Page
      websiteKey={websiteKey}
      title={'Page: ' + title + ' (' + websiteKey + ')'}
    >
      <BlockModal
        websiteKey={websiteKey}
        pageId={pageId}
        currentBlock={currentBlock}
        handleModalClose={handleStopEditingBlock}
        refreshData={fetchBlockData}
      />
      <Modal open={Boolean(editingMeta)} onClose={handleCloseMeta}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            Page meta - {metaModalLabel}
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handleSaveMeta(e)}>
            <FormControl fullWidth>
              {metaModalType === 'boolean' ? (
                <Switch
                  id={'field-key'}
                  checked={metaModalValue}
                  onChange={(e) => handleEditMeta(e)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              ) : (
                <TextField
                  id={'field-key'}
                  type={metaModalType}
                  label={metaModalLabel}
                  variant={'standard'}
                  value={metaModalValue}
                  onChange={(e) => handleEditMeta(e)}
                  disabled={loading}
                  fullWidth
                />
              )}
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
              >
                Save
              </Button>
            </FormControl>
          </form>
        </Box>
      </Modal>
      <Modal open={Boolean(newBlockType)} onClose={handleStopAddingBlock}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            New content block
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handleSaveNewBlock(e)}>
            <FormControl fullWidth>
              <InputLabel id="menu-select-label">block type</InputLabel>
              <Select
                labelId="menu-select-label"
                id="menu-select"
                value={newBlockType}
                label="block type"
                onChange={handleEditBlockType}
              >
                <MenuItem value={'hero'}>Hero block</MenuItem>
                <MenuItem value={'text'}>Text block</MenuItem>
                <MenuItem value={'mediaText'}>Media & text block</MenuItem>
                <MenuItem value={'form'}>Form block</MenuItem>
                <MenuItem value={'chapterHeading'}>
                  Chapter heading block
                </MenuItem>
                <MenuItem value={'tableOfContents'}>
                  Table of contents block
                </MenuItem>
                <MenuItem value={'contactInfo'}>Contact info block</MenuItem>
                <MenuItem value={'testimonial'}>Testimonial block</MenuItem>
              </Select>
              <br />
              <TextField
                id={'block-sort'}
                label={'block sort'}
                type={'number'}
                variant={'standard'}
                value={newBlockSort}
                onChange={(e) => handleEditBlockSort(e)}
                disabled={loading}
                fullWidth
              />
              <br />
              <TextField
                id={'block-title'}
                label={'block title'}
                variant={'standard'}
                value={newBlockTitle}
                onChange={(e) => handleEditBlockTitle(e)}
                disabled={loading}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <AddIcon />}
              >
                Add block
              </Button>
            </FormControl>
          </form>
        </Box>
      </Modal>
      <Modal open={softDeleting} onClose={handleStopSoftDeleting}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            Delete page? ({title})
          </Typography>

          <Divider />
          <br />
          <Typography variant="p" gutterBottom component="div">
            <b>CAUTION: page deletion is permanent and cannot be undone.</b>
          </Typography>
          <br />
          <Typography variant="p" gutterBottom component="div">
            This will delete page <b>"{title}"</b> from the <b>{websiteKey}</b>{' '}
            website.
          </Typography>
          <br />

          <form onSubmit={(e) => handleDeletePage(e)}>
            <FormControl fullWidth>
              <TextField
                id={'delete-confirmation'}
                label={'type DELETE to continue'}
                variant={'standard'}
                value={deleteValue}
                onChange={(e) => handleEditDelete(e)}
                disabled={loading}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading || deleteValue !== 'DELETE'}
                endIcon={loading ? <PendingIcon /> : <DeleteIcon />}
              >
                Delete
              </Button>
            </FormControl>
          </form>
        </Box>
      </Modal>

      <Typography variant="h6" gutterBottom component="div">
        Page details
      </Typography>
      <List component="nav" dense={true}>
        <ListItemButton
          key={'meta-url'}
          onClick={() =>
            handleOpenMeta({
              key: 'url',
              label: 'Page URL',
              value: url,
              type: 'text',
            })
          }
          disabled={pageId === 'homepage' || pageId === '404'}
        >
          <ListItemText primary={'page url'} secondary={url} />
        </ListItemButton>
        <ListItemButton
          key={'meta-title'}
          onClick={() =>
            handleOpenMeta({
              key: 'title',
              label: 'Title (internal)',
              value: title,
              type: 'text',
            })
          }
          disabled={pageId === 'homepage' || pageId === '404'}
        >
          <ListItemText primary={'title (internal)'} secondary={title} />
        </ListItemButton>
        <ListItemButton
          key={'meta-published'}
          onClick={() =>
            handleOpenMeta({
              key: 'published',
              label: 'published',
              value: published,
              type: 'boolean',
            })
          }
        >
          <ListItemText primary={'published'} secondary={String(published)} />
        </ListItemButton>
        <ListItemButton
          key={'meta-sort'}
          onClick={() =>
            handleOpenMeta({
              key: 'sort',
              label: 'Sort (internal)',
              value: sort,
              type: 'number',
            })
          }
          disabled={pageId === 'homepage' || pageId === '404'}
        >
          <ListItemText primary={'sort (internal)'} secondary={sort} />
        </ListItemButton>
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        SEO
      </Typography>
      <List component="nav" dense={true}>
        <ListItemButton
          key={'seo-title'}
          onClick={() =>
            handleOpenMeta({
              key: 'seo.title',
              label: 'SEO title',
              value: seoTitle,
              type: 'text',
            })
          }
        >
          <ListItemText primary={'page title'} secondary={seoTitle} />
        </ListItemButton>
        <ListItemButton
          key={'seo-description'}
          onClick={() =>
            handleOpenMeta({
              key: 'seo.description',
              label: 'SEO description',
              value: seoDescription,
              type: 'text',
            })
          }
          disabled={pageId === '404'}
        >
          <ListItemText primary={'description'} secondary={seoDescription} />
        </ListItemButton>
        <ListItemButton
          key={'seo-image-id'}
          onClick={() =>
            handleOpenMeta({
              key: 'seo.imageId',
              label: 'SEO image ID',
              value: seoImageId,
              type: 'text',
            })
          }
        >
          <ListItemText primary={'image ID'} secondary={seoImageId} />
        </ListItemButton>
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        Content blocks
      </Typography>
      <List component="nav" dense={true}>
        {blocks.map((block) => {
          return (
            <ListItemButton
              key={block.firebaseId}
              onClick={() => handleBlockClick(block)}
            >
              <ListItemText
                primary={block.sort + '. ' + block.type}
                secondary={block.title}
              />
            </ListItemButton>
          );
        })}
        <br />
        <ListItemButton key={'new-block'} onClick={() => handleAddBlock()}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={'Add new block'} />
        </ListItemButton>
      </List>
      {pageId !== 'homepage' && pageId !== '404' && (
        <>
          <br />
          <Divider />
          <br />

          <Typography variant="h6" gutterBottom component="div">
            Admin
          </Typography>
          <List component="nav" dense={true}>
            <ListItemButton
              key={'admin-delete'}
              onClick={() => handleSoftDeletePage()}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText
                primary={'delete page'}
                secondary={'protected double-check'}
              />
            </ListItemButton>
          </List>
        </>
      )}
    </Page>
  );
}

export default BlocksPage;
