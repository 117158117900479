import { initializeApp, getApps, getApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseApp = () => {
  const appName = 'cmsFirebaseApp';

  // return existing firebase app instance, if it exists
  const existingFirebaseApps = getApps() || [];
  const appCount = existingFirebaseApps.length;
  if (appCount > 0) {
    return getApp(appName);
  }

  // initialize new firebase app instance
  const firebaseConfig = {
    apiKey: 'AIzaSyCQfkXe12XPQ0wwL63S9UWGagTfRkVst1o',
    authDomain: 'websites-5c508.firebaseapp.com',
    projectId: 'websites-5c508',
    storageBucket: 'websites-5c508.appspot.com',
    messagingSenderId: '1085709004032',
    appId: '1:1085709004032:web:5fb5b19c536b527e9497e5',
  };
  const firebaseApp = initializeApp(firebaseConfig, appName);
  return firebaseApp;
};
export default firebaseApp();

export const firebaseAuth = () => {
  const auth = getAuth(firebaseApp());
  setPersistence(auth, browserLocalPersistence);
  return auth;
};

export const firestore = getFirestore(firebaseApp(), {
  experimentalForceLongPolling: true,
});
