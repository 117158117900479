import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { firebaseAuth } from '../../utils/firebase';
import { signOut } from 'firebase/auth';
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom';

const routes = [
  { path: '/' },
  { path: '/:websiteKey/entries' },
  { path: '/:websiteKey/pages/:pageId' },
  { path: '/:websiteKey/pages' },
  { path: '/:websiteKey/settings' },
  { path: '/:websiteKey/menus' },
  { path: '/:websiteKey/forms' },
];
const useCurrentPath = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location);
  return route.path;
};

const websiteKeyMap = {
  all: 'All Forms',
  decimis: 'Decimis',
  vogelco: 'VogelCo',
  knights: 'Knights',
  lunchlist: 'Lunchlist',
  prayerful: 'Prayerful',
  securityAi: 'Security AI',
  techCheck: 'TechCheck',
  terrain: 'Flock & Banner',
  classroom: 'Classroom',
  dayton: 'Dayton',
  kitchen: 'Kitchen',
  will: 'Communications Will',
  marketplaceGame: 'Marketplace Game',
  ticTacGame: 'Tic Tac Awesome',
  oloTriviaGame: 'OLO Trivia Game',
  homeHunt: 'Home Scavenger Hunt Game',
  rpg: 'RPG',
};
const websiteKeyArray = [
  { key: 'all', value: 'All Forms' },
  { key: 'decimis', value: 'Decimis' },
  { key: 'vogelco', value: 'VogelCo' },
  { key: 'knights', value: 'Knights' },
  { key: 'lunchlist', value: 'Lunchlist' },
  { key: 'prayerful', value: 'Prayerful' },
  { key: 'securityAi', value: 'Security AI' },
  { key: 'techCheck', value: 'TechCheck' },
  { key: 'terrain', value: 'Flock & Banner' },
  { key: 'classroom', value: 'Classroom' },
  { key: 'dayton', value: 'Dayton' },
  { key: 'kitchen', value: 'Kitchen' },
  { key: 'will', value: 'Communications Will' },
  { key: 'marketplaceGame', value: 'Marketplace Game' },
  { key: 'ticTacGame', value: 'Tic Tac Awesome' },
  { key: 'oloTriviaGame', value: 'OLO Trivia Game' },
  { key: 'homeHunt', value: 'Home Scavenger Hunt Game' },
  { key: 'rpg', value: 'RPG' },
];

const sidebarArray = [
  { label: 'Form Entries', value: '/:websiteKey/entries', all: true },
  { label: 'Pages', value: '/:websiteKey/pages', all: false },
  { label: 'Menus', value: '/:websiteKey/menus', all: false },
  { label: 'Forms', value: '/:websiteKey/forms', all: false },
  { label: 'Settings', value: '/:websiteKey/settings', all: false },
];

export default function Header({ websiteKey }) {
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [keySelectOpen, openKeySelect] = useState(false);
  const [sidebarSelectOpen, openSidebarSelect] = useState(false);

  const handleSidebarClick = (event) => {
    setAnchorEl(event.currentTarget);
    openKeySelect(false);
    openSidebarSelect(true);
  };
  const handleSidebarSelect = (selectedSidebar) => {
    handleClose();
    if (websiteKey === 'all' || websiteKey === undefined) {
      navigate('/all/entries');
    } else {
      const newPath = selectedSidebar.replace(':websiteKey', websiteKey);
      navigate(newPath);
    }
  };

  const handleWebsiteClick = (event) => {
    setAnchorEl(event.currentTarget);
    openSidebarSelect(false);
    openKeySelect(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    openKeySelect(false);
    openSidebarSelect(false);
  };
  const handleKeySelect = (selectedKey) => {
    handleClose();
    if (selectedKey === 'all') {
      navigate('/all/entries');
    } else {
      if (currentPath === '/') {
        navigate('/' + selectedKey + '/entries');
      } else {
        const newPath = currentPath
          .replace(':websiteKey', selectedKey)
          .replace(':pageId', '');
        navigate(newPath);
      }
    }
  };

  const handleLogout = () => {
    signOut(firebaseAuth());
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleSidebarClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="sidebar-menu"
            anchorEl={anchorEl}
            open={sidebarSelectOpen}
            onClose={handleClose}
          >
            {sidebarArray.map((option) => {
              if (
                (websiteKey !== 'all' && websiteKey !== undefined) ||
                option.all
              ) {
                return (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleSidebarSelect(option.value)}
                  >
                    {option.label}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Menu>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {websiteKeyMap[websiteKey]}
          </Typography>
          <div>
            <Button onClick={handleWebsiteClick} color="inherit">
              Toggle Website
            </Button>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={keySelectOpen}
              onClose={handleClose}
            >
              {websiteKeyArray.map((option, index) => (
                <MenuItem
                  key={option.key}
                  onClick={() => handleKeySelect(option.key)}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <Button onClick={handleLogout} color="inherit">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
