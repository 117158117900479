import { useState, useEffect } from 'react';
import { firestore } from '../../utils/firebase';
import {
  query,
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import Page from '../../components/page';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PendingIcon from '@mui/icons-material/Pending';
import Button from '@mui/material/Button';
import get from 'lodash/get';
import find from 'lodash/find';

const modalStyle = {
  position: 'fixed',
  top: '10%',
  right: '10%',
  bottom: '50%',
  left: '10%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

function SettingsPage() {
  const { websiteKey } = useParams();
  const [settingsDocs, setSettingsDocs] = useState([]);
  const [settingsObject, setSettingsObject] = useState({});
  const [selectedField, setSelectedField] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // fetch entries from firestore
    const fetchSettings = async () => {
      // get primary settings object
      const settingsRef = doc(firestore, 'websites', websiteKey);
      const theSettingsDoc = await getDoc(settingsRef);
      const theSettingsDocData = await theSettingsDoc.data();
      setSettingsObject(theSettingsDocData);

      // get settings subcollection docs
      const entriesCollection = collection(
        firestore,
        'websites',
        websiteKey,
        'settings'
      );
      let q = query(entriesCollection);
      const fetchedDocs = await getDocs(q);
      const theDocs = [];
      fetchedDocs.forEach((doc) => {
        theDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setSettingsDocs(theDocs);
    };
    fetchSettings();
  }, [websiteKey]);

  const fetchSettingsData = async () => {
    // get primary settings object
    const settingsRef = doc(firestore, 'websites', websiteKey);
    const theSettingsDoc = await getDoc(settingsRef);
    const theSettingsDocData = await theSettingsDoc.data();
    setSettingsObject(theSettingsDocData);

    // get settings subcollection docs
    const entriesCollection = collection(
      firestore,
      'websites',
      websiteKey,
      'settings'
    );
    let q = query(entriesCollection);
    const fetchedDocs = await getDocs(q);
    const theDocs = [];
    fetchedDocs.forEach((doc) => {
      theDocs.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setSettingsDocs(theDocs);
  };

  const handleFieldClick = (settingsDoc, fieldName, value) => {
    setSelectedField({ doc: settingsDoc, field: fieldName, value: value });
    setFieldValue(value);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    if (!loading) {
      setModalOpen(false);
    }
  };

  const handleFieldEdit = (e) => {
    setFieldValue(e.target.value);
  };
  const handleFieldSave = async (e) => {
    e.preventDefault();
    setLoading(true);

    // update firestore
    if (selectedField) {
      const selectedDoc = get(selectedField, ['doc'], null);
      const selectedDocField = get(selectedField, ['field'], null);
      if (!selectedDoc) {
        // primary document
        const docRef = doc(firestore, 'websites', websiteKey);
        await setDoc(
          docRef,
          { [selectedDocField]: fieldValue },
          { merge: true }
        );
      } else {
        // subcollection document
        const docRef = doc(
          firestore,
          'websites',
          websiteKey,
          'settings',
          selectedDoc
        );
        await setDoc(
          docRef,
          { [selectedDocField]: fieldValue },
          { merge: true }
        );
      }

      // refresh data
      fetchSettingsData();
      setTimeout(() => {
        setLoading(false);
        setModalOpen(false);
      }, 500);
    }
  };

  const globalObject = find(settingsDocs, (o) => o.id === 'global');
  const title = get(globalObject, ['title'], '');
  const description = get(globalObject, ['description'], '');
  const favicon = get(globalObject, ['faviconImageId'], '');

  const footerObject = find(settingsDocs, (o) => o.id === 'footer');
  const copyright = get(footerObject, ['copyright'], '©');

  const notesId = get(settingsObject, ['id'], '');
  const notesUrl = get(settingsObject, ['url'], 'https://');
  const notesStagingUrl = get(settingsObject, ['staging'], '');
  const notesText = get(settingsObject, ['notes'], '');

  const competitionObject = find(settingsDocs, (o) => o.id === 'competition');
  const competitionNotes = get(competitionObject, ['notes'], '');

  const selectedDoc = get(selectedField, ['doc'], null);
  const selectedDocField = get(selectedField, ['field'], null);

  if (websiteKey === 'all') return null;

  return (
    <Page websiteKey={websiteKey} title={'Settings (' + websiteKey + ')'}>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            {websiteKey} {selectedDoc} settings
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handleFieldSave(e)}>
            <TextField
              id={'settings-field-input'}
              label={selectedDocField}
              variant={'standard'}
              value={fieldValue}
              onChange={handleFieldEdit}
              disabled={loading}
              multiline={Boolean(
                selectedDoc === null && selectedDocField === 'notes'
              )}
              rows={
                Boolean(selectedDoc === null && selectedDocField === 'notes') *
                4
              }
              fullWidth
            />
            <br />
            <br />
            <Button
              variant={'contained'}
              type={'submit'}
              disabled={loading}
              endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
            >
              Save
            </Button>
          </form>
        </Box>
      </Modal>

      <Typography variant="h6" gutterBottom component="div">
        Notes
      </Typography>
      <List component="nav" dense={true}>
        <ListItemButton
          key={'notes-id'}
          onClick={() => handleFieldClick(null, 'id', notesId)}
        >
          <ListItemText primary={'site id'} secondary={notesId} />
        </ListItemButton>

        <ListItemButton
          key={'notes-url'}
          onClick={() => handleFieldClick(null, 'url', notesUrl)}
        >
          <ListItemText primary={'website url'} secondary={notesUrl} />
        </ListItemButton>

        <ListItemButton
          key={'notes-staging'}
          onClick={() => handleFieldClick(null, 'staging', notesStagingUrl)}
        >
          <ListItemText
            primary={'staging website url'}
            secondary={notesStagingUrl}
          />
        </ListItemButton>

        <ListItemButton
          key={'notes-text'}
          onClick={() => handleFieldClick(null, 'notes', notesText)}
        >
          <ListItemText primary={'general notes'} secondary={notesText} />
        </ListItemButton>
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        Global settings
      </Typography>
      <List component="nav" dense={true}>
        <ListItemButton
          key={'global-title'}
          onClick={() => handleFieldClick('global', 'title', title)}
        >
          <ListItemText primary={'site title'} secondary={title} />
        </ListItemButton>

        <ListItemButton
          key={'global-description'}
          onClick={() => handleFieldClick('global', 'description', description)}
        >
          <ListItemText primary={'site description'} secondary={description} />
        </ListItemButton>

        <ListItemButton
          key={'global-favicon'}
          onClick={() => handleFieldClick('global', 'faviconImageId', favicon)}
        >
          <ListItemText primary={'favicon image id'} secondary={favicon} />
        </ListItemButton>
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        Footer settings
      </Typography>
      <List component="nav" dense={true}>
        <ListItemButton
          key={'footer-copyright'}
          onClick={() => handleFieldClick('footer', 'copyright', copyright)}
        >
          <ListItemText primary={'copyright text'} secondary={copyright} />
        </ListItemButton>
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        Competitor research
      </Typography>
      <List component="nav" dense={true}>
        <ListItemButton
          key={'competition-notes'}
          onClick={() =>
            handleFieldClick('competition', 'notes', competitionNotes)
          }
        >
          <ListItemText
            primary={'competition notes'}
            secondary={competitionNotes}
          />
        </ListItemButton>
      </List>
    </Page>
  );
}

export default SettingsPage;
