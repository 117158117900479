import { useParams } from 'react-router-dom';
import Page from '../../components/page';
import Typography from '@mui/material/Typography';
import FormEntriesTable from './formEntriesTable';

function DashboardPage() {
  const { websiteKey } = useParams();
  const calculatedWebsiteKey = websiteKey || 'all';

  return (
    <Page
      websiteKey={websiteKey}
      title={'Form Entries (' + calculatedWebsiteKey + ')'}
    >
      <Typography variant="h6" gutterBottom component="div">
        Entries
      </Typography>
      <FormEntriesTable websiteKey={calculatedWebsiteKey} />
    </Page>
  );
}

export default DashboardPage;
