import { useState, useEffect } from 'react';
import { firestore } from '../../utils/firebase';
import {
  query,
  collection,
  getDocs,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import Page from '../../components/page';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PendingIcon from '@mui/icons-material/Pending';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

const modalStyle = {
  position: 'fixed',
  top: '10%',
  right: '10%',
  bottom: '10%',
  left: '10%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};
const fabStyle = {
  position: 'fixed',
  bottom: 40,
  right: 40,
};
const menuNameMap = {
  mainMenu: 'Main menu',
  secondaryMenu: 'Secondary menu',
  footerMenu: 'Footer menu',
};

function MenusPage() {
  const { websiteKey } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [mainMenu, setMainMenu] = useState([]);
  const [secondaryMenu, setSecondaryMenu] = useState([]);
  const [footerMenu, setFooterMenu] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMenuId, setEditMenuId] = useState('mainMenu');
  const [editTitle, setEditTitle] = useState('');
  const [editUrl, setEditUrl] = useState('');
  const [editSort, setEditSort] = useState(10);

  useEffect(() => {
    // fetch entries from firestore
    const fetchSettings = async () => {
      setMainMenu(await fetchMenu(websiteKey, 'mainMenu'));
      setSecondaryMenu(await fetchMenu(websiteKey, 'secondaryMenu'));
      setFooterMenu(await fetchMenu(websiteKey, 'footerMenu'));
    };
    fetchSettings();
  }, [websiteKey]);

  const fetchMenu = async (websiteKey, menuKey) => {
    // fetch main menu items
    const menuCollection = collection(
      firestore,
      'websites',
      websiteKey,
      menuKey
    );
    let q = query(menuCollection);
    const fetchedDocs = await getDocs(q);
    const theDocs = [];
    fetchedDocs.forEach((doc) => {
      theDocs.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return sortBy(theDocs, (o) => o.sort);
  };

  const fetchUpdatedMenus = async () => {
    setMainMenu(await fetchMenu(websiteKey, 'mainMenu'));
    setSecondaryMenu(await fetchMenu(websiteKey, 'secondaryMenu'));
    setFooterMenu(await fetchMenu(websiteKey, 'footerMenu'));
  };

  const handleFieldClick = (menuName, item) => {
    if (item) {
      setSelectedItem(item);
      setEditTitle(item.title);
      setEditUrl(item.url);
      setEditSort(item.sort);
    } else {
      setSelectedItem(null);
      setEditMenuId('mainMenu');
      setEditTitle('');
      setEditUrl('/');
      setEditSort(10);
    }
    setModalOpen(true);
    setSelectedMenu(menuName);
  };
  const handleModalClose = () => {
    if (!loading) {
      setModalOpen(false);
      setSelectedMenu(null);
    }
  };

  const handlePublish = async (e) => {
    e.preventDefault();
    setLoading(true);

    // update firestore
    if (selectedMenu && selectedItem) {
      // update existing menu item
      const docRef = doc(
        firestore,
        'websites',
        websiteKey,
        selectedMenu,
        selectedItem.id
      );
      await setDoc(
        docRef,
        {
          title: editTitle,
          url: editUrl,
          sort: editSort,
        },
        { merge: false }
      );
    } else {
      // add new menu item
      const menuCollection = collection(
        firestore,
        'websites',
        websiteKey,
        editMenuId
      );
      await addDoc(menuCollection, {
        title: editTitle,
        url: editUrl,
        sort: editSort,
      });
    }

    // refresh data
    fetchUpdatedMenus();
    setTimeout(() => {
      setLoading(false);
      setModalOpen(false);
    }, 500);
  };
  const handleDelete = () => {
    setLoading(true);

    // delete from firestore
    if (selectedMenu && selectedItem) {
      const itemId = get(selectedItem, ['id'], null);
      const docRef = doc(
        firestore,
        'websites',
        websiteKey,
        selectedMenu,
        itemId
      );
      deleteDoc(docRef);
    }

    // refresh data
    fetchUpdatedMenus();
    setTimeout(() => {
      setLoading(false);
      setModalOpen(false);
    }, 500);
  };

  const handleEditMenu = (e) => {
    setEditMenuId(e.target.value);
  };
  const handleEditTitle = (e) => {
    setEditTitle(e.target.value);
  };
  const handleEditUrl = (e) => {
    setEditUrl(e.target.value);
  };
  const handleEditSort = (e) => {
    setEditSort(e.target.value);
  };

  if (websiteKey === 'all') return null;

  return (
    <Page websiteKey={websiteKey} title={'Menus (' + websiteKey + ')'}>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            {selectedMenu ? menuNameMap[selectedMenu] : 'New menu item'}
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handlePublish(e)}>
            <FormControl fullWidth>
              {!selectedMenu && (
                <>
                  <InputLabel id="menu-select-label">Add to</InputLabel>
                  <Select
                    labelId="menu-select-label"
                    id="menu-select"
                    value={editMenuId}
                    label="Add to"
                    onChange={handleEditMenu}
                  >
                    <MenuItem value={'mainMenu'}>Main menu</MenuItem>
                    <MenuItem value={'secondaryMenu'}>Secondary menu</MenuItem>
                    <MenuItem value={'footerMenu'}>Footer menu</MenuItem>
                  </Select>
                  <br />
                </>
              )}

              <TextField
                id={'field-title'}
                label={'title'}
                variant={'standard'}
                value={editTitle}
                onChange={handleEditTitle}
                disabled={loading}
                fullWidth
              />
              <br />
              <TextField
                id={'field-url'}
                label={'relative url'}
                variant={'standard'}
                value={editUrl}
                onChange={handleEditUrl}
                disabled={loading}
                fullWidth
              />
              <br />
              <TextField
                id={'field-sort'}
                label={'sort order'}
                variant={'standard'}
                value={editSort}
                onChange={handleEditSort}
                disabled={loading}
                type={'number'}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
              >
                {selectedMenu ? 'Save updates' : 'Add menu item'}
              </Button>
            </FormControl>
          </form>
          {selectedMenu && (
            <>
              <br />
              <Button
                variant="outlined"
                color="error"
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <DeleteIcon />}
                onClick={handleDelete}
              >
                Delete menu item
              </Button>
            </>
          )}
        </Box>
      </Modal>

      <Fab
        variant="extended"
        color="primary"
        sx={fabStyle}
        onClick={() => handleFieldClick(null, null)}
      >
        <AddIcon /> Add menu item
      </Fab>

      <Typography variant="h6" gutterBottom component="div">
        Main menu
      </Typography>
      <List component="nav" dense={true}>
        {mainMenu.map((item) => {
          return (
            <ListItemButton
              key={item.id}
              onClick={() => handleFieldClick('mainMenu', item)}
            >
              <ListItemText
                primary={item.sort + '. ' + item.title}
                secondary={item.url}
              />
            </ListItemButton>
          );
        })}
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        Secondary menu
      </Typography>
      <List component="nav" dense={true}>
        {secondaryMenu.map((item) => {
          return (
            <ListItemButton
              key={item.id}
              onClick={() => handleFieldClick('secondaryMenu', item)}
            >
              <ListItemText
                primary={item.sort + '. ' + item.title}
                secondary={item.url}
              />
            </ListItemButton>
          );
        })}
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        Footer menu
      </Typography>
      <List component="nav" dense={true}>
        {footerMenu.map((item) => {
          return (
            <ListItemButton
              key={item.id}
              onClick={() => handleFieldClick('footerMenu', item)}
            >
              <ListItemText
                primary={item.sort + '. ' + item.title}
                secondary={item.url}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Page>
  );
}

export default MenusPage;
