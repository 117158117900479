import { useState, useEffect } from 'react';
import { firestore } from '../../../utils/firebase';
import { setDoc, doc, deleteDoc } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PendingIcon from '@mui/icons-material/Pending';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import get from 'lodash/get';
import { customFields, imageFields } from './fields';

const modalStyle = {
  position: 'fixed',
  top: '10%',
  right: '10%',
  bottom: '10%',
  left: '10%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};
const subFieldStyle = {
  background: '#f3f3f3',
  marginTop: '0',
  marginBottom: '20px',
  padding: '20px',
  paddingTop: '20px',
  paddingBottom: '40px',
};

function BlockModal({
  websiteKey,
  pageId,
  currentBlock,
  handleModalClose,
  refreshData,
}) {
  const [loading, setLoading] = useState(false);
  const [blockDetails, setBlockDetails] = useState({});

  useEffect(() => {
    setBlockDetails(currentBlock);
  }, [websiteKey, pageId, currentBlock]);

  const handleSaveBlock = async (e) => {
    e.preventDefault();
    setLoading(true);
    const blockId = get(currentBlock, ['firebaseId'], null);
    if (websiteKey && pageId && blockId) {
      // convert block details to JSON
      const convertedData = {
        ...blockDetails,
      };
      Object.keys(convertedData).forEach((key) => {
        if (key.startsWith('image.')) {
          convertedData['image'] = {
            ...convertedData['image'],
            [key.replace('image.image-', '')]: convertedData[key],
          };
          delete convertedData[key];
        }
      });

      // save updates to firestore
      const docRef = doc(
        firestore,
        'websites',
        websiteKey,
        'pages',
        pageId,
        'blocks',
        blockId
      );
      await setDoc(docRef, convertedData, { merge: true });
    }

    // refresh data
    refreshData();
    setTimeout(() => {
      setLoading(false);
      handleModalClose();
    }, 500);
  };
  const handleEditValue = (key, value) => {
    setBlockDetails({
      ...blockDetails,
      [key]: value,
    });
  };
  const handleDeleteBlock = async () => {
    setLoading(true);
    const blockId = get(currentBlock, ['firebaseId'], null);
    if (websiteKey && pageId && blockId) {
      // delete from firestore
      const docRef = doc(
        firestore,
        'websites',
        websiteKey,
        'pages',
        pageId,
        'blocks',
        blockId
      );
      deleteDoc(docRef);
    }

    // refresh data
    refreshData();
    setTimeout(() => {
      setLoading(false);
      handleModalClose();
    }, 500);
  };

  const blockId = get(blockDetails, ['id'], '');
  const blockType = get(blockDetails, ['type'], 'text');
  const blockTitle = get(blockDetails, ['title'], '');
  const blockSort = get(blockDetails, ['sort'], 0);

  return (
    <Modal open={Boolean(currentBlock)} onClose={handleModalClose}>
      <Box sx={modalStyle}>
        <Typography gutterBottom variant="h6" component="h2">
          Block details ({blockId})
        </Typography>

        <Divider />
        <br />

        <form onSubmit={(e) => handleSaveBlock(e)}>
          <FormControl fullWidth>
            <InputLabel id="menu-select-label">block type</InputLabel>
            <Select
              labelId="menu-select-label"
              id="menu-select"
              value={blockType}
              label="block type"
              onChange={(e) => handleEditValue('type', e.target.value)}
            >
              <MenuItem value={'hero'}>Hero block</MenuItem>
              <MenuItem value={'text'}>Text block</MenuItem>
              <MenuItem value={'mediaText'}>Media & text block</MenuItem>
              <MenuItem value={'form'}>Form block</MenuItem>
              <MenuItem value={'chapterHeading'}>
                Chapter heading block
              </MenuItem>
              <MenuItem value={'tableOfContents'}>
                Table of contents block
              </MenuItem>
              <MenuItem value={'contactInfo'}>Contact info block</MenuItem>
              <MenuItem value={'testimonial'}>Testimonial block</MenuItem>
            </Select>
            <br />
            <TextField
              id={'block-id'}
              label={'block id'}
              variant={'standard'}
              value={blockId}
              onChange={(e) => handleEditValue('id', e.target.value)}
              disabled={loading}
              fullWidth
            />
            <br />
            <TextField
              id={'block-sort'}
              label={'block sort'}
              type={'number'}
              variant={'standard'}
              value={blockSort}
              onChange={(e) => handleEditValue('sort', e.target.value)}
              disabled={loading}
              fullWidth
            />
            <br />
            <TextField
              id={'block-title'}
              label={'block title'}
              variant={'standard'}
              value={blockTitle}
              onChange={(e) => handleEditValue('title', e.target.value)}
              disabled={loading}
              fullWidth
            />

            {customFields[blockType].map((field, index) => {
              const fieldType = get(field, ['type'], null);
              const fieldValue = get(blockDetails, [field.id], '');

              const fieldConditions = get(field, ['conditions'], []);
              let shouldHide = false;
              if (fieldConditions.length > 0) {
                fieldConditions.forEach((condition) => {
                  const conditionValue = get(
                    blockDetails,
                    [condition.key],
                    null
                  );
                  if (!condition.values.includes(conditionValue)) {
                    shouldHide = true;
                  }
                });
              }
              if (shouldHide) return null;

              switch (fieldType) {
                case 'text':
                case 'number':
                case 'textarea':
                  return (
                    <div key={field.id}>
                      <br />
                      <TextField
                        id={field.id}
                        label={field.label}
                        type={fieldType}
                        variant={'standard'}
                        multiline={fieldType === 'textarea'}
                        minRows={fieldType === 'textarea' ? 4 : 1}
                        value={fieldValue}
                        onChange={(e) =>
                          handleEditValue(field.id, e.target.value)
                        }
                        disabled={loading}
                        fullWidth
                      />
                    </div>
                  );
                case 'select':
                  return (
                    <div key={field.id}>
                      <br />
                      <TextField
                        id={field.id}
                        select
                        label={field.label}
                        type={fieldType}
                        variant={'standard'}
                        value={fieldValue}
                        onChange={(e) =>
                          handleEditValue(field.id, e.target.value)
                        }
                        disabled={loading}
                        fullWidth
                      >
                        {field.options.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                  );
                case 'image':
                  return (
                    <div key={field.id}>
                      <br />
                      <p>Image</p>
                      <Box sx={subFieldStyle}>
                        {imageFields.map((imageField) => {
                          const imageFieldValue = get(
                            blockDetails,
                            ['image.' + imageField.id],
                            get(
                              blockDetails,
                              ['image', imageField.id.replace('image-', '')],
                              ''
                            )
                          );
                          return (
                            <TextField
                              id={imageField.id}
                              key={imageField.id}
                              label={imageField.label}
                              type={imageField.type}
                              variant={'standard'}
                              multiline={imageField.type === 'textarea'}
                              maxRows={imageField.type === 'textarea' ? 4 : 1}
                              value={imageFieldValue}
                              onChange={(e) =>
                                handleEditValue(
                                  'image.' + imageField.id,
                                  e.target.value
                                )
                              }
                              disabled={loading}
                              fullWidth
                            />
                          );
                        })}
                      </Box>
                    </div>
                  );
                case 'repeater':
                  return (
                    <div key={field.id}>
                      <br />
                      <p>
                        TODO: make repeater functional... (currently hand-edited
                        in Firestore)
                      </p>
                      <TextField
                        id={field.id}
                        label={'item count'}
                        type={'number'}
                        variant={'standard'}
                        multiline={false}
                        minRows={1}
                        value={fieldValue}
                        onChange={(e) =>
                          handleEditValue(field.id, e.target.value)
                        }
                        disabled={loading}
                        fullWidth
                      />
                    </div>
                  );
                default:
                  break;
              }
              return (
                <div key={index}>
                  <br />
                  <p>
                    <b>MISSING FIELD</b>
                  </p>
                </div>
              );
            })}

            <br />
            <br />
            <Button
              variant={'contained'}
              type={'submit'}
              disabled={loading}
              endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
            >
              Save
            </Button>
          </FormControl>
        </form>

        <br />
        <Button
          variant="outlined"
          color="error"
          disabled={loading}
          endIcon={loading ? <PendingIcon /> : <DeleteIcon />}
          onClick={handleDeleteBlock}
        >
          Delete content block
        </Button>
      </Box>
    </Modal>
  );
}

export default BlockModal;
