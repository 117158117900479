import { useState, useEffect } from 'react';
import { firestore } from '../../utils/firebase';
import { query, collection, getDocs, addDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/page';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PendingIcon from '@mui/icons-material/Pending';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { get, sortBy } from 'lodash';

const modalStyle = {
  position: 'fixed',
  top: '10%',
  right: '10%',
  bottom: '10%',
  left: '10%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};
const fabStyle = {
  position: 'fixed',
  bottom: 40,
  right: 40,
};
const newPageTemplate = {
  url: '/new',
  title: '',
  sort: 10,
  published: true,
};

function PagesPage() {
  const { websiteKey } = useParams();
  const [pages, setPages] = useState([]);
  const [pagesFetched, setPagesFetched] = useState(false);
  const [homepage, setHomepage] = useState({ id: 'homepage' });
  const [errorPage, setErrorPage] = useState({ id: '404' });
  const [newPage, setNewPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // fetch forms from firestore
    const fetchPages = async () => {
      setPages([]);
      setHomepage({ id: 'homepage' });
      setErrorPage({ id: '404' });
      setPagesFetched(false);
      const pagesCollection = collection(
        firestore,
        'websites',
        websiteKey,
        'pages'
      );
      let q = query(pagesCollection);
      const fetchedDocs = await getDocs(q);
      const theDocs = [];
      fetchedDocs.forEach((doc) => {
        const docData = {
          id: doc.id,
          ...doc.data(),
        };
        switch (doc.id) {
          case 'homepage':
            setHomepage(docData);
            break;
          case '404':
            setErrorPage(docData);
            break;
          default:
            theDocs.push(docData);
            break;
        }
      });
      setPages(sortBy(theDocs, (o) => o.sort));
      setPagesFetched(true);
    };
    fetchPages();
  }, [websiteKey]);

  const handlePageClick = (pageData) => {
    navigate('/' + websiteKey + '/pages/' + pageData.id);
  };
  const handleOpenNewPage = () => {
    setNewPage(newPageTemplate);
  };
  const handleCloseNewPage = () => {
    setNewPage(null);
  };
  const handleEditNewPageValue = (key, value) => {
    setNewPage({
      ...newPage,
      [key]: value,
    });
  };
  const handleSaveNewPage = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newPageUrl = get(newPage, ['url'], '');
    const newPageTitle = get(newPage, ['title'], '');
    const newPageSort = get(newPage, ['sort'], 10);

    let newPageId = null;
    if (newPageUrl) {
      // add new menu item
      const pagesCollection = collection(
        firestore,
        'websites',
        websiteKey,
        'pages'
      );
      const docRef = await addDoc(pagesCollection, {
        title: newPageTitle,
        url: newPageUrl,
        sort: newPageSort,
        seo: {
          title: newPageTitle,
          description: '',
          imageId: '',
        },
      });
      newPageId = docRef.id;
    }
    setTimeout(() => {
      setLoading(false);
      setNewPage(null);
      if (newPageId) {
        navigate('/' + websiteKey + '/pages/' + newPageId);
      }
    }, 500);
  };

  if (websiteKey === 'all') return null;

  const homepageExists = Boolean(get(homepage, ['url'], null));
  const errorPageExists = Boolean(get(errorPage, ['url'], null));
  const generateText = pagesFetched ? 'CLICK TO GENERATE' : '...';

  const newPageUrl = get(newPage, ['url'], '');
  const newPageTitle = get(newPage, ['title'], '');
  const newPageSort = get(newPage, ['sort'], 10);

  // show all pages
  return (
    <Page websiteKey={websiteKey} title={'Pages (' + websiteKey + ')'}>
      <Modal open={Boolean(newPage)} onClose={handleCloseNewPage}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            Add new page
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handleSaveNewPage(e)}>
            <FormControl fullWidth>
              <TextField
                id={'new-url'}
                type={'text'}
                label={'relative page url'}
                variant={'standard'}
                value={newPageUrl}
                onChange={(e) => handleEditNewPageValue('url', e.target.value)}
                disabled={loading}
                fullWidth
              />
              <br />
              <TextField
                id={'new-title'}
                type={'text'}
                label={'page title (internal)'}
                variant={'standard'}
                value={newPageTitle}
                onChange={(e) =>
                  handleEditNewPageValue('title', e.target.value)
                }
                disabled={loading}
                fullWidth
              />
              <br />
              <TextField
                id={'new-sort'}
                type={'number'}
                label={'sort order (internal)'}
                variant={'standard'}
                value={newPageSort}
                onChange={(e) => handleEditNewPageValue('sort', e.target.value)}
                disabled={loading}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
              >
                Save
              </Button>
            </FormControl>
          </form>
        </Box>
      </Modal>

      <Fab
        variant="extended"
        color="primary"
        sx={fabStyle}
        onClick={() => handleOpenNewPage()}
      >
        <AddIcon /> Add new page
      </Fab>

      <Typography variant="h6" gutterBottom component="div">
        Core pages
      </Typography>
      <List component="nav" dense={true}>
        <ListItemButton
          key={'homepage'}
          onClick={() => handlePageClick(homepage)}
        >
          <ListItemText
            primary={'homepage'}
            secondary={homepageExists ? '/' : generateText}
          />
        </ListItemButton>
        <ListItemButton key={'404'} onClick={() => handlePageClick(errorPage)}>
          <ListItemText
            primary={'404 page'}
            secondary={errorPageExists ? '/404' : generateText}
          />
        </ListItemButton>
      </List>

      <br />
      <Divider />
      <br />

      <Typography variant="h6" gutterBottom component="div">
        Pages
      </Typography>
      <List component="nav" dense={true}>
        {pages.map((page) => {
          const published = get(page, ['published'], false);
          const pageTitle = get(page, ['title'], '', page.id).toLowerCase();
          return (
            <ListItemButton key={page.id} onClick={() => handlePageClick(page)}>
              <ListItemText
                primary={page.sort + '. ' + pageTitle}
                secondary={(!published ? 'HIDDEN * ' : '') + page.url}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Page>
  );
}

export default PagesPage;
