import { useState, useEffect } from 'react';
import { firestore } from '../../../utils/firebase';
import {
  query,
  collection,
  where,
  orderBy,
  limit,
  getDocs,
  doc,
  setDoc,
} from 'firebase/firestore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import FormEntryModal from './modal';
import { DateTime } from 'luxon';
import get from 'lodash/get';

function FormEntriesTable({ websiteKey }) {
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [entries, setEntries] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);

  const fetchEntries = async () => {
    const resultsPerPage = 50;
    const constraints = [];
    const entriesCollection = collection(firestore, 'formEntries');
    if (websiteKey !== 'all')
      constraints.push(where('keys.website', '==', websiteKey));
    if (!showCompleted)
      constraints.push(where('status', 'in', ['new', 'read', 'responded']));
    let q = query(
      entriesCollection,
      // orderBy('status', 'asc'),
      orderBy('created', 'desc'),
      limit(resultsPerPage),
      ...constraints
    );
    const formEntryDocs = await getDocs(q);
    const theEntries = [];
    formEntryDocs.forEach((doc) => {
      theEntries.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setEntries(theEntries);
  };

  useEffect(() => {
    // fetch entries from firestore
    fetchEntries();
  });

  const fetchUpdatedData = async () => {
    fetchEntries();
  };

  const handleEntryClick = (entryData) => {
    // mark as read, if applicable
    const status = get(entryData, ['status'], 'new');
    if (status === 'new') {
      const entryId = get(entryData, ['id'], '-1');
      const entryRef = doc(firestore, 'formEntries', entryId);
      setDoc(entryRef, { status: 'read' }, { merge: true });
      entryData.status = 'read';
    }

    // open the selected entry modal
    setSelectedEntry(entryData);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <>
          <FormEntryModal
            entry={selectedEntry}
            refreshData={fetchUpdatedData}
          />
        </>
      </Modal>

      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={!showCompleted}
              onChange={() => setShowCompleted(!showCompleted)}
            />
          }
          label={showCompleted ? 'All submissions' : 'Active submissions'}
        />
      </FormGroup>

      {entries.length === 0 ? (
        <p>
          <i>No form entries found matching the filters</i>
        </p>
      ) : (
        <List component="nav" dense={true}>
          {entries.map((entry) => {
            const selectedId = get(selectedEntry, ['id'], null);
            const entryId = get(entry, ['id'], '-1');
            const status = get(entry, ['status'], 'new');
            const name = get(entry, ['data', 'name'], null);
            const email = get(entry, ['data', 'email'], null);
            const combinedKey = get(entry, ['combinedKey'], 'unknown');
            const rawDateSeconds = get(
              get(entry, ['created'], null),
              ['seconds'],
              null
            );
            const entryDate = DateTime.fromSeconds(
              rawDateSeconds
            ).toLocaleString(DateTime.DATETIME_MED);

            let primary = <>{name + ' | ' + email}</>;
            let secondary = <>{entryDate + ' | ' + combinedKey}</>;
            switch (status) {
              case 'new':
                primary = <b>{name + ' | ' + email}</b>;
                secondary = <b>{entryDate + ' | ' + combinedKey}</b>;
                break;
              case 'read':
                // primary = <>{name + ' | ' + email}</>;
                // secondary = <>{entryDate + ' | ' + combinedKey}</>;
                break;
              case 'completed':
                primary = <>✅ {name + ' | ' + email}</>;
                break;
              case 'spam':
                primary = <>⚠️ {name + ' | ' + email}</>;
                break;
              default:
                break;
            }

            return (
              <ListItemButton
                key={entry.id}
                selected={selectedId === entryId}
                onClick={() => handleEntryClick(entry)}
              >
                <ListItemText primary={primary} secondary={secondary} />
              </ListItemButton>
            );
          })}
        </List>
      )}
    </>
  );
}

export default FormEntriesTable;
