import { useState, useEffect } from 'react';
import { EmailAuthProvider } from 'firebase/auth';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { firebaseAuth } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [config, setConfig] = useState(null);
  const [auth, setAuth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const uiConfig = {
      signInFlow: 'popup',
      // signInSuccessUrl: '/',
      signInOptions: [EmailAuthProvider.PROVIDER_ID],
      adminRestrictedOperation: { status: true },
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          // save firebase credentials (automatic)
          // redirect to homepage
          navigate('/');
        },
      },
    };
    setConfig(uiConfig);
    setAuth(firebaseAuth);
  }, [navigate]);

  return (
    <div className="LoginPage">
      <h1>Login</h1>
      {config && auth && (
        <StyledFirebaseAuth uiConfig={config} firebaseAuth={auth} />
      )}
    </div>
  );
}

export default LoginPage;
