import { useEffect, useState } from 'react';
import { firestore } from '../../utils/firebase';
import {
  query,
  collection,
  getDocs,
  doc,
  setDoc,
  deleteField,
} from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import Page from '../../components/page';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import PendingIcon from '@mui/icons-material/Pending';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { get, sortBy } from 'lodash';

const modalStyle = {
  position: 'fixed',
  top: '10%',
  right: '10%',
  bottom: '10%',
  left: '10%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};
const fabStyle = {
  position: 'fixed',
  bottom: 40,
  right: 40,
};
const fieldsStyle = {
  paddingLeft: '20px',
};

const defaultNewFormOptions = {
  key: 'contact',
  template: 'contact',
};
const formTemplates = {
  none: {
    // formKey: (user input)
    buttonText: 'Submit',
  },
  contact: {
    // formKey: (user input)
    buttonText: 'Send message',
    confirmation:
      "Thank you for your message. We'll get back you as soon as possible.",
    fields: {
      name: {
        sort: 10,
        label: 'Name',
        placeholder: 'Name',
        error: 'Please enter your name',
        required: true,
        type: 'text',
      },
      email: {
        sort: 20,
        label: 'Email',
        placeholder: 'Email',
        error: 'Please enter a valid email address',
        required: true,
        type: 'email',
      },
      message: {
        sort: 30,
        label: 'Message',
        placeholder: 'Message',
        error: 'Please enter a message to share with our team',
        required: true,
        type: 'textarea',
      },
    },
  },
};
const defaultEditedMeta = {
  form: '',
  label: '',
  key: '',
  value: '',
};
const defaultEditedField = {
  form: '',
  newId: null,
  id: null,
  sort: 10,
  type: 'text',
  label: '',
  placeholder: '',
  error: '',
  required: false,
};

function FormsPage() {
  const { websiteKey } = useParams();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newFormOptions, setNewFormOptions] = useState(defaultNewFormOptions);
  const [newFormModalOpen, setNewFormModalOpen] = useState(false);
  const [editedMeta, setEditedMeta] = useState(defaultEditedMeta);
  const [metaModalOpen, setMetaModalOpen] = useState(false);
  const [editedField, setEditedField] = useState(defaultEditedField);
  const [fieldModalOpen, setFieldModalOpen] = useState(false);

  useEffect(() => {
    // fetch forms from firestore
    const fetchForms = async () => {
      const formsCollection = collection(
        firestore,
        'websites',
        websiteKey,
        'forms'
      );
      let q = query(formsCollection);
      const fetchedDocs = await getDocs(q);
      const theDocs = [];
      fetchedDocs.forEach((doc) => {
        theDocs.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setForms(theDocs);
    };
    fetchForms();
  }, [websiteKey]);

  const fetchFormData = async () => {
    const formsCollection = collection(
      firestore,
      'websites',
      websiteKey,
      'forms'
    );
    let q = query(formsCollection);
    const fetchedDocs = await getDocs(q);
    const theDocs = [];
    fetchedDocs.forEach((doc) => {
      theDocs.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    setForms(theDocs);
  };

  const handleModalClose = () => {
    setNewFormModalOpen(false);
    setMetaModalOpen(false);
    setFieldModalOpen(false);
  };

  const handleAddForm = () => {
    setNewFormModalOpen(true);
    setNewFormOptions(defaultNewFormOptions);
  };
  const handleEditNewForm = (field, newValue) => {
    setNewFormOptions({
      ...newFormOptions,
      [field]: newValue,
    });
  };
  const handleSaveNewForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newFormId = get(newFormOptions, ['key'], null);
    const templateKey = get(newFormOptions, ['template'], null);
    // add to firestore
    if (newFormId && templateKey) {
      const newFormDoc = doc(
        firestore,
        'websites',
        websiteKey,
        'forms',
        newFormId
      );
      await setDoc(newFormDoc, {
        ...formTemplates[templateKey],
        formKey: newFormId,
      });
    }

    // refresh data
    fetchFormData();
    setTimeout(() => {
      setLoading(false);
      handleModalClose();
    }, 500);
  };

  const handleOpenEditedMeta = (form, label, key, value) => {
    setEditedMeta({
      form: form,
      label: label,
      key: key,
      value: value,
    });
    setMetaModalOpen(true);
  };
  const handleEditEditedMeta = (e) => {
    setEditedMeta({
      ...editedMeta,
      value: e.target.value,
    });
  };
  const handleSaveEditedMeta = async (e) => {
    e.preventDefault();
    setLoading(true);
    const metaForm = get(editedMeta, ['form'], null);
    const metaKey = get(editedMeta, ['key'], null);
    const metaValue = get(editedMeta, ['value'], null);
    if (metaForm && metaKey && metaValue) {
      const newFormDoc = doc(
        firestore,
        'websites',
        websiteKey,
        'forms',
        metaForm
      );
      await setDoc(newFormDoc, { [metaKey]: metaValue }, { merge: true });
    }

    // refresh data
    fetchFormData();
    setTimeout(() => {
      setLoading(false);
      handleModalClose();
    }, 500);
  };

  const handleOpenEditedField = (form, fieldData) => {
    const theFieldData = fieldData || defaultEditedField;
    setEditedField({
      ...theFieldData,
      form: form,
    });
    setFieldModalOpen(true);
  };
  const handleEditEditedField = (key, value) => {
    setEditedField({
      ...editedField,
      [key]: value,
    });
  };
  const handleSaveEditedField = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formId = get(editedField, ['form'], null);
    const newFieldId = get(editedField, ['newId'], null);
    const fieldId = get(editedField, ['id'], '');
    const sort = get(editedField, ['sort'], 0);
    const required = get(editedField, ['required'], false);
    const type = get(editedField, ['type'], '');
    const label = get(editedField, ['label'], '');
    const placeholder = get(editedField, ['placeholder'], '');
    const error = get(editedField, ['error'], '');
    if (formId && (fieldId || newFieldId)) {
      const formDoc = doc(firestore, 'websites', websiteKey, 'forms', formId);
      await setDoc(
        formDoc,
        {
          fields: {
            [fieldId || newFieldId]: {
              sort: sort,
              required: required,
              type: type,
              label: label,
              placeholder: placeholder,
              error: error,
            },
          },
        },
        { merge: true }
      );
    }

    // refresh data
    fetchFormData();
    setTimeout(() => {
      setLoading(false);
      handleModalClose();
    }, 500);
  };
  const handleDeleteEditedField = async () => {
    setLoading(true);

    // delete field from firestore
    const editedForm = get(editedField, ['form'], null);
    if (fieldModalOpen && editedForm) {
      const currentField = get(editedField, ['id'], null);
      const docRef = doc(
        firestore,
        'websites',
        websiteKey,
        'forms',
        editedForm
      );
      await setDoc(
        docRef,
        { fields: { [currentField]: deleteField() } },
        { merge: true }
      );
    }

    // refresh data
    fetchFormData();
    setTimeout(() => {
      setLoading(false);
      handleModalClose();
    }, 500);
  };

  if (websiteKey === 'all') return null;

  return (
    <Page websiteKey={websiteKey} title={'Forms (' + websiteKey + ')'}>
      <Modal open={newFormModalOpen} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            New form
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handleSaveNewForm(e)}>
            <FormControl fullWidth>
              <InputLabel id="menu-select-label">Form template</InputLabel>
              <Select
                labelId="menu-select-label"
                id="menu-select"
                value={newFormOptions.template}
                label="Form template"
                onChange={(e) => handleEditNewForm('template', e.target.value)}
              >
                <MenuItem value={'none'}>Blank form</MenuItem>
                <MenuItem value={'contact'}>Contact form</MenuItem>
              </Select>
              <br />
              <TextField
                id={'field-id'}
                label={'form key (hidden)'}
                variant={'standard'}
                value={newFormOptions.key}
                onChange={(e) => handleEditNewForm('key', e.target.value)}
                disabled={loading}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
              >
                Create form
              </Button>
            </FormControl>
          </form>
        </Box>
      </Modal>
      <Modal open={metaModalOpen} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            {editedMeta.form} form - {editedMeta.label}
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handleSaveEditedMeta(e)}>
            <FormControl fullWidth>
              <TextField
                id={'field-key'}
                label={editedMeta.label}
                variant={'standard'}
                value={editedMeta.value}
                onChange={(e) => handleEditEditedMeta(e)}
                disabled={loading}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
              >
                Save
              </Button>
            </FormControl>
          </form>
        </Box>
      </Modal>
      <Modal open={fieldModalOpen} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography gutterBottom variant="h6" component="h2">
            {editedField.form} form -{' '}
            {editedField.id ? editedField.id : 'new field'}
          </Typography>

          <Divider />
          <br />

          <form onSubmit={(e) => handleSaveEditedField(e)}>
            <FormControl fullWidth>
              <InputLabel id="menu-select-label">field type</InputLabel>
              <Select
                labelId="menu-select-label"
                id="menu-select"
                value={editedField.type}
                label="field type"
                onChange={(e) => handleEditEditedField('type', e.target.value)}
              >
                <MenuItem value={'text'}>text</MenuItem>
                <MenuItem value={'email'}>email</MenuItem>
                <MenuItem value={'phone'}>phone</MenuItem>
                <MenuItem value={'number'}>number</MenuItem>
                <MenuItem value={'textarea'}>text area</MenuItem>
              </Select>
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={editedField.required}
                    onChange={(e) =>
                      handleEditEditedField('required', e.target.checked)
                    }
                  />
                }
                label={editedField.required ? 'required: yes' : 'required: no'}
              />
              <br />
              {!editedField.id && (
                <>
                  <TextField
                    id={'field-id'}
                    label={'field id (hidden)'}
                    variant={'standard'}
                    value={editedField.newId}
                    onChange={(e) =>
                      handleEditEditedField('newId', e.target.value)
                    }
                    disabled={loading}
                    fullWidth
                  />
                  <br />
                </>
              )}
              <TextField
                id={'field-sort'}
                label={'sort order'}
                variant={'standard'}
                value={editedField.sort}
                onChange={(e) => handleEditEditedField('sort', e.target.value)}
                disabled={loading}
                type={'number'}
                fullWidth
              />
              <br />
              <TextField
                id={'field-label'}
                label={'field label'}
                variant={'standard'}
                value={editedField.label}
                onChange={(e) => handleEditEditedField('label', e.target.value)}
                disabled={loading}
                fullWidth
              />
              <br />
              <TextField
                id={'field-placeholder'}
                label={'field placeholder'}
                variant={'standard'}
                value={editedField.placeholder}
                onChange={(e) =>
                  handleEditEditedField('placeholder', e.target.value)
                }
                disabled={loading}
                fullWidth
              />
              <br />
              <TextField
                id={'field-error'}
                label={'error message'}
                variant={'standard'}
                value={editedField.error}
                onChange={(e) => handleEditEditedField('error', e.target.value)}
                disabled={loading}
                fullWidth
              />
              <br />
              <br />
              <Button
                variant={'contained'}
                type={'submit'}
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <ChevronRightIcon />}
              >
                {editedField.id ? 'Save' : 'Add field'}
              </Button>
            </FormControl>
          </form>
          {editedField.id && (
            <>
              <br />
              <Button
                variant="outlined"
                color="error"
                disabled={loading}
                endIcon={loading ? <PendingIcon /> : <DeleteIcon />}
                onClick={handleDeleteEditedField}
              >
                Delete field
              </Button>
            </>
          )}
        </Box>
      </Modal>

      <Fab
        variant="extended"
        color="primary"
        sx={fabStyle}
        onClick={() => handleAddForm()}
      >
        <AddIcon /> Add new form
      </Fab>

      {forms.map((form, index) => {
        const formId = get(form, ['id'], '');
        const buttonText = get(form, ['buttonText'], '');
        const confirmation = get(form, ['confirmation'], '');
        const fieldsObj = get(form, ['fields'], {});
        const fields = Object.keys(fieldsObj).map((key) => {
          return {
            id: key,
            ...fieldsObj[key],
          };
        });
        const sortedFields = sortBy(fields, (o) => o.sort);

        const dividerElement =
          index > 0 ? (
            <>
              <br />
              <Divider />
              <br />
            </>
          ) : null;

        return (
          <div key={formId}>
            {dividerElement}
            <Typography variant="h6" gutterBottom component="div">
              {formId} form
            </Typography>
            <List component="nav" dense={true}>
              <ListItem key={formId + '-form-id'}>
                <ListItemText
                  primary={'form key (hidden)'}
                  secondary={formId}
                />
              </ListItem>
              <ListItemButton
                key={formId + '-button-text'}
                onClick={() =>
                  handleOpenEditedMeta(
                    formId,
                    'button text',
                    'buttonText',
                    buttonText
                  )
                }
              >
                <ListItemText primary={'button text'} secondary={buttonText} />
              </ListItemButton>
              <ListItemButton
                key={formId + '-confirmation'}
                onClick={() =>
                  handleOpenEditedMeta(
                    formId,
                    'confirmation message',
                    'confirmation',
                    confirmation
                  )
                }
              >
                <ListItemText
                  primary={'confirmation message'}
                  secondary={confirmation}
                />
              </ListItemButton>
            </List>
            <Box sx={fieldsStyle}>
              <List component="nav" dense={true}>
                {sortedFields.map((field) => {
                  const fieldId = get(field, ['id'], '');
                  const sort = get(field, ['sort'], 0);
                  const required = get(field, ['required'], false);
                  const type = get(field, ['type'], '');
                  const label = get(field, ['label'], '');
                  const placeholder = get(field, ['placeholder'], '');
                  const error = get(field, ['error'], '');

                  return (
                    <ListItemButton
                      key={formId + '-field-' + fieldId}
                      onClick={() => handleOpenEditedField(formId, field)}
                    >
                      <ListItemText
                        primary={`${sort}. ${label}` + (required ? '*' : '')}
                        secondary={`id: ${fieldId} | type: ${type} | placeholder: "${placeholder}" | error: "${error}"`}
                      />
                    </ListItemButton>
                  );
                })}

                <br />
                <ListItemButton
                  key={formId + '-new-field'}
                  onClick={() => handleOpenEditedField(formId, null)}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Add new field'} />
                </ListItemButton>
                <br />
              </List>
            </Box>
          </div>
        );
      })}
    </Page>
  );
}

export default FormsPage;
