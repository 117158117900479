// forEach loop that awaits async responses
const asyncForEach = async (dataSnapshot, childFunction) => {
  const callbacks = [];
  dataSnapshot.forEach((childSnapshot) => {
    callbacks.push(childFunction(childSnapshot, callbacks.length));
  });
  await Promise.all(callbacks);
};

export default asyncForEach;
