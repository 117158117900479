import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ChatIcon from '@mui/icons-material/Chat';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../../utils/firebase';

const modalStyle = {
  position: 'fixed',
  top: '10%',
  right: '10%',
  bottom: '10%',
  left: '10%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

function FormEntryModal({ entry, refreshData }) {
  const entryId = get(entry, ['id'], null);
  const websiteKey = get(entry, ['keys', 'website'], 'unknown');
  const formKey = get(entry, ['keys', 'form'], 'unknown');
  const name = get(entry, ['data', 'name'], null);
  const email = get(entry, ['data', 'email'], null);
  const originalStatus = get(entry, ['status'], 'new');
  const rawDateSeconds = get(get(entry, ['created'], null), ['seconds'], null);
  const entryDate = DateTime.fromSeconds(rawDateSeconds).toLocaleString(
    DateTime.DATETIME_MED
  );

  const data = get(entry, ['data'], {});
  const dataPoints = [];
  Object.keys(data).forEach((key) => {
    dataPoints.push({ key: key, value: data[key] });
  });

  const fraud = get(entry, ['fraud'], {});
  const fraudPoints = [];
  Object.keys(fraud).forEach((key) => {
    fraudPoints.push({ key: key, value: fraud[key] });
  });

  const [status, setStatus] = useState(originalStatus);
  const handleStatusChange = (e) => {
    // update the status in firestore
    if (entryId) {
      const newStatus = e.target.value;
      const entryRef = doc(firestore, 'formEntries', entryId);
      setDoc(entryRef, { status: newStatus }, { merge: true });
      setStatus(newStatus);
      // refresh data
      setTimeout(() => {
        refreshData();
      }, 500);
    }
  };

  return (
    <Box sx={modalStyle}>
      <Typography gutterBottom variant="h6" component="h2">
        {name} - {email}
      </Typography>
      <Stack direction="row" spacing={1}>
        <Chip label={websiteKey} />
        <Chip label={formKey + ' form'} variant="outlined" />
      </Stack>

      <br />
      <FormControl fullWidth>
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          labelId="status-label"
          id="status-select"
          value={status}
          label="Status"
          onChange={handleStatusChange}
        >
          <MenuItem value={'new'}>Unread</MenuItem>
          <MenuItem value={'read'}>Read</MenuItem>
          <MenuItem value={'responded'}>Responded</MenuItem>
          <MenuItem value={'completed'}>Completed</MenuItem>
          <MenuItem value={'spam'}>Spam</MenuItem>
        </Select>
      </FormControl>

      <List dense={true}>
        <ListItem key={'entry-date'} disablePadding>
          <ListItemIcon>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText primary={'date'} secondary={entryDate} />
        </ListItem>

        {dataPoints.map((dataPoint) => {
          const uid = 'data-' + dataPoint.key;
          let icon = <RadioButtonCheckedIcon />;
          switch (dataPoint.key) {
            case 'name':
              icon = <PersonIcon />;
              break;
            case 'email':
              icon = <MailIcon />;
              break;
            case 'phone':
              icon = <SmartphoneIcon />;
              break;
            case 'message':
              icon = <ChatIcon />;
              break;
            default:
              break;
          }
          return (
            <ListItem key={uid} disablePadding>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={dataPoint.key}
                secondary={dataPoint.value}
              />
            </ListItem>
          );
        })}

        <br />
        <Divider />
        <br />

        {fraudPoints.map((fraudPoint) => {
          const uid = 'fraud-' + fraudPoint.key;
          return (
            <ListItem key={uid} disablePadding>
              <ListItemText
                primary={fraudPoint.key}
                secondary={fraudPoint.value}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default FormEntryModal;
