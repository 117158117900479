import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import LoginPage from './pages/loginPage';
import DashboardPage from './pages/dashboardPage';
import BlocksPage from './pages/blocksPage';
import PagesPage from './pages/pagesPage';
import FormsPage from './pages/formsPage';
import SettingsPage from './pages/settingsPage';
import MenusPage from './pages/menusPage';
import { firebaseAuth } from './utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

function App() {
  const [user, setUser] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // wait a moment for firebase auth to load before routing
    setTimeout(() => {
      setReady(true);
    }, 500);
  }, []);
  useEffect(() => {
    // handle firebase auth changes
    const unsubscribe = onAuthStateChanged(firebaseAuth(), (user) => {
      setUser(user);
      setReady(true);
    });
    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <div className="App">
      {ready && (
        <Routes>
          <Route element={<LoginRoute user={user} />}>
            <Route path="login" element={<LoginPage />} />
          </Route>
          <Route element={<ProtectedRoute user={user} />}>
            <Route index element={<DashboardPage />} />
            <Route path=":websiteKey/entries" element={<DashboardPage />} />
            <Route path=":websiteKey/pages/:pageId" element={<BlocksPage />} />
            <Route path=":websiteKey/pages" element={<PagesPage />} />
            <Route path=":websiteKey/settings" element={<SettingsPage />} />
            <Route path=":websiteKey/menus" element={<MenusPage />} />
            <Route path=":websiteKey/forms" element={<FormsPage />} />
            <Route path="*" element={<h1>404</h1>} />
          </Route>
        </Routes>
      )}
    </div>
  );
}

const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  // return children ? children : <Outlet context={[user, websiteKey]} />;
  return children ? children : <Outlet context={[]} />;
};

const LoginRoute = ({ user, children }) => {
  if (user) {
    return <Navigate to="/" replace />;
  }
  return children ? children : <Outlet context={[user]} />;
};

export default App;
