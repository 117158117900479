import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Header from '../header';
import './index.scss';

export default function Page({ websiteKey, title, children }) {
  return (
    <>
      <Header websiteKey={websiteKey} />
      <Container className={'page'}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Divider />
        <div className={'spacer'} />
        {children}
      </Container>
    </>
  );
}
